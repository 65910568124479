





























































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import { Route } from "@/store/models/route";
import { RoadLink, RouteRoad } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";

@Component({
    components:{}
})
export default class RoadLinkCreateView extends Vue {
    routeRoad: RouteRoad = {} as RouteRoad;
    routeList: Route[] = [];
    roadLinkList: RoadLink[] = [];

    async created() {
        const id: any = this.$route.query["id"]

        await metaStore.loadRoutesDD();
        await metaStore.getRoadLink();
        this.routeList = metaStore.routesList;
        this.roadLinkList = metaStore.roadLinkList;

        if(id) {
            await metaStore.getRouteRoadById(id);
            this.routeRoad = metaStore.routeRoad;
        }
    }

    get AdminRouter() {
        return AdminRouter;
    }

    async onSubmit() {
        const id = this.$route.query["id"]
        let isValid = await this.$validator.validateAll();
        if (!isValid) {
            return;
        }
        if(id) {
            await metaStore.patchRouteRoad(this.routeRoad);
            this.$snotify.success("RouteRoad Changed Successfully");
        }
        else{
            await metaStore.createRouteRoad(this.routeRoad);
            this.$snotify.success("RouteRoad Created Successfully");
        }
        this.$router.push(AdminRouter.RouteRoad);
    }

    routeRemoteMethod(query: any) {
        if (query !== '') {
            setTimeout(() => {
                this.routeList = metaStore.routesList.filter(item => {
                    return item.name.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
                });
            }, 200);
        } else {
            this.routeList = metaStore.routesList;
        }
    }

    roadNetworkRemoteMethod(query: any) {
        if (query !== '') {
            setTimeout(() => {
                this.roadLinkList = metaStore.roadLinkList.filter(item => {
                    return item.roadLinkName.toLowerCase()
                    .indexOf(query.toLowerCase()) > -1;
                });
            }, 200);
        } else {
            this.roadLinkList = metaStore.roadLinkList;
        }
    }

    onCancel() {
        this.$router.push(AdminRouter.RouteRoad);
    }
}
